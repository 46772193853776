<template>
  <div class="person">
    <topbar
      :title="woDeZiLiao"
      :right="GR_pas"
      @rightClick="rightClick"
      :rightType="2"
    ></topbar>
    <div class="personInfo">
      <van-cell-group>
        <van-cell
          @click="showCon"
          :title="username"
          :value="userInfo.firstName || ''"
          size="large"
          class="van-cell-arrow"
          title-class="titleColor"
        >
          <template #right-icon>
            <van-icon name="arrow" class="arrow" color="#CACBCC"/>
          </template>
        </van-cell>
        <van-cell title-class="titleColor" value-class="valueClass" :title="email" :value="userInfo.email" size="large" />
        <van-cell title-class="titleColor" :title="shouJiHaoMa" :value="userInfo.phoneNo" size="large" />
        <van-cell
          :title="new_GRZL_xingBie"
          :value="userInfo.gender"
          title-class="titleColor"
          is-link
          size="large"
          class="van-cell-arrow"
          @click="showSexBox"
        >
          <template #right-icon>
            <van-icon name="arrow" class="arrow" color="#CACBCC"/>
          </template>
        </van-cell>
        <van-cell
          :title="birthday"
          :value="userInfo.birthday?userInfo.birthday.month + '-' + userInfo.birthday.day:''"
          title-class="titleColor"
          size="large"
          class="van-cell-arrow"
        >
        </van-cell>
      </van-cell-group>
      <div class="btn" @click="showLogoutFrame">{{ new_GRZL_dengChu }}</div>
      <!-- <p class="delPerson" @click="delPerson">{{ new_GRZL_shanChuZhangHu }}</p> -->
    </div>
    <confim
      @closeCon="closeConfim"
      :title="username"
      :btnText="confirm"
      v-show="showConfim"
      :show="showConfim"
      :showType="3"
    ></confim>
    <van-popup
      v-model:show="showSex"
      round
      position="bottom"
      :style="{ height: '35%' }"
      class="popup"
    >
      <van-cell
        v-for="item in genderInfo"
        :key="item.genderId"
        @click="selectSex(item.genderId)"
        :value="item.genderName"
        size="large"
      />
      <p @click="showSexBox" class="popupBtn">{{$store.state.language==1?"確認":"Confirm"}}</p>
    </van-popup>
    <div class="birthdayBox" :class="{ active: showBirthday }">
      <van-datetime-picker
        ref="dataPicker"
        v-model="currentDate"
        type="date"
        @cancel="showBirthdayBox"
        @confirm="selectBirthday"
        :min-date="new Date(1900, 0, 1)"
        :max-date="new Date(2200, 10, 1)"
        :formatter="formatter"
      />
    </div>
    <!-- 提示框 -->
    <div class="confirmBox">
      <food-confirm
        v-show="showConfirm"
        :title="confirmTitle"
        :info="info"
        :style="style"
        @confirm="backPage"
        @cancle="del"
        :leftBtn="leftBtn"
        :rightBtn="rightBtn"
      ></food-confirm>
    </div>
    <!-- 推出彈窗 -->
    <confirm-box
      v-show="showLogout"
      :title="queDingDengChu"
      :show="true"
      :cancelBtn="cancelBtn"
      :finishBtn="finishBtn"
      @finish="logout"
      @closeBox="showLogoutFrame"
    ></confirm-box>
  </div>
</template>
<script>
import Topbar from "../../components/Topbar.vue";
import Confim from "../../components/common/Confim.vue";
import FoodConfirm from "../../components/food/OrderConfirm.vue";
import ConfirmBox from "../../components/common/CnfirmBox.vue";
import language from "../../assets/js/language";
import { postPaas, postDC } from "../../assets/utils/request";
export default {
  data() {
    return {
      userInfo: {
        name: "用戶名",
        phone: "12313123134",
        handlePhone: " +85 212488166",
        sex: 1,
        birthday: {},
        birthdayTime: 0,
        currentDate: 949334400000,
      },
      showLogout: false,
      showConfirm: false,
      confirmTitle: "确定刪除帳戶嗎？",
      leftBtn: "手滑了",
      rightBtn: "刪除",
      info: "<div style='padding:0.1rem 0.2rem 0;box-sizing:border-box'><p style='margin-bottom:0.1rem'>您在肯德基帳戶中存儲的記錄和積分將被永久刪除。</p><p style='margin-bottom:0.1rem'>您將不會再收到發送到您帳戶的任何消息或電話。</p><p>您將無法再登錄或使用服務。</p></div>",
      style: "color:#E4022B;text-align:left;",
      genderInfo: {},
      showConfim: false,
      showSex: false,
      sex: 0,
      showBirthday: false,
      lanType: this.$store.state.language,
      // 靜態文字
      finishBtn: this.$store.state.language == 1 ? "登出" : "Log Out",
      cancelBtn: this.$store.state.language == 1 ? "取消" : "Cancel",
      woDeZiLiao: "",
      username: "",
      birthday: "",
      gengGaiMiMa: "",
      GR_pas: "",
      logoutText: "",
      shouJiHaoMa: "",
      email: "",
      new_GRZL_xingBie: "",
      confirm: "",
      new_GRZL_shanChuZhangHu: "",
      new_GRZL_dengChu: "",
      queDingDengChu: "",
    };
  },
  methods: {
    showLogoutFrame() {
      this.showLogout = !this.showLogout;
    },
    // 推出登陆
    async logout() {
      var dcParams = {
        actionName: "candao.user.logout",
        content: {},
      };
      var paasParams = {
        actionName: "candao.member.logout",
        content: {},
      };
      let paasResult = await postPaas("UserUnify", paasParams);

      let dcResult = await postDC("Action", dcParams);
      this.$store.commit("logout");
      this.$store.commit("clearCart");
      localStorage.removeItem("orderMap");
      localStorage.removeItem("KFCAdvert");
      if (dcResult.status == 1 && paasResult.status == 1) {
        console.log(1);
      }
      localStorage.removeItem("store");
      this.$store.commit("reloadVuex");
      this.showLogout = false;
      this.$router.push("/");
    },
    showCon() {
      this.showConfim = true;
      this.closePicker();
    },
    delPerson() {
      this.showConfirm = !this.showConfirm;
    },
    backPage() {
      this.showConfirm = !this.showConfirm;
    },
    // 删除账号
    async del() {
      // let params = {
      //   actionName: "",
      //   content: {},
      // };
      // let result = await postPaas("", params);
    },
    // 查询用户性别信息
    async getGenderInfo() {
      let params = {
        actionName: "candao.member.miscUserGender",
        content: {},
      };
      if (this.$store.state.language == 1) {
        params.content.language = "TC";
      }
      let result = await postPaas("UserUnify", params);
      if (result.status == 1 && result.data) {
        this.genderInfo = result.data.data;
      }
    },
    // 修改用户信息
    async updateUserInfo(type) {
      let params = {
        actionName: "candao.member.userUpdate",
        content: {
          genderId: this.userInfo.genderId,
          birthday: {
            month: this.userInfo.birthday.month,
            day: this.userInfo.birthday.day,
          },
          firstName: this.userInfo.firstName,
        },
      };
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        if (type == 1) {
          this.showConfim = false;
          this.closePicker();
        } else if (type == 2) {
          this.closePicker();
        }
      }
    },
    // 修改用户名
    closeConfim(param) {
      if (param && param.name) {
        this.userInfo.firstName = param.name;
      } else {
        this.showConfim = false;
        return;
      }
      this.updateUserInfo(1);
    },
    showSexBox() {
      this.showSex = !this.showSex;
      this.closePicker();
      if (this.showSex) {
        this.updateUserInfo();
      }
    },
    selectSex(sex) {
      this.userInfo.sex = sex;
      this.userInfo.genderId = sex;
      // this.showSexBox();
      // this.closePicker();
    },
    showBirthdayBox() {
      this.currentDate = new Date();
      var year = new Date().getFullYear() + "年";
      var month = new Date().getMonth() + 1;
      month = month < 10 ? "0" + month : month;
      month += "月";
      var day = new Date().getDate();
      day = day < 10 ? "0" + day : day;
      day += "日";
      this.$refs.dataPicker.getPicker().setValues([year, month, day]);
      this.showBirthday = !this.showBirthday;
    },
    formatter(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      return val;
    },
    closePicker() {
      this.showBirthday = false;
    },
    // 修改生日
    selectBirthday() {
      var date = this.$refs.dataPicker
        .getPicker()
        .getValues()
        .map((item) => {
          return item.replace(/[年|月|日]/, "");
        });
      this.userInfo.birthday.month = date[1];
      this.userInfo.birthday.day = date[2];
      this.updateUserInfo(2);
    },
    //     // 获取用户信息
    async getUserInfo() {
      let params = {
        actionName: "candao.member.userProfileGet",
        content: {},
      };
      if (this.$store.state.language == 1) {
        params.content.language = "TC";
      }
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        this.userInfo = result.data;
      }
    },
    // 修改密码
    rightClick() {
      this.$router.push("/editPas");
    },
    // 設置語言
    setlanguage() {
      var type = "chinese";
      if (this.lanType != 1) {
        type = "english";
      }
      this.woDeZiLiao = language.woDeZiLiao[type];
      this.username = language.username[type];
      this.birthday = language.birthday[type];
      this.gengGaiMiMa = language.gengGaiMiMa[type];
      this.GR_pas = language.GR_pas[type];
      this.logoutText = language.logout[type];
      this.queDingDengChu = language.queDingDengChu[type];
      this.shouJiHaoMa = language.shouJiHaoMa[type];
      this.email = language.email[type];
      this.new_GRZL_xingBie = language.new_GRZL_xingBie[type];
      this.confirm = language.confirm[type];
      this.new_GRZL_shanChuZhangHu = language.new_GRZL_shanChuZhangHu[type];
      this.new_GRZL_dengChu = language.new_GRZL_dengChu[type];
    },
  },
  created() {
    this.setlanguage();
    this.getGenderInfo();
    this.getUserInfo();
  },
  components: {
    Topbar,
    Confim,
    FoodConfirm,
    ConfirmBox,
  },
};
</script>
<style scoped>
.person {
  height: 100vh;
}
.personInfo {
  width: 100%;
  height: 100%;
  padding: 0.16rem;
  box-sizing: border-box;
  text-align: left !important;
  background-color: #f2f3f5;
}
.van-cell-group {
  border-radius: 0.08rem;
  overflow: hidden;
}
.title-style {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}
.btn {
  width: 100%;
  height: 0.44rem;
  background-color: #e4022b;
  background-size: cover;
  margin-top: 0.48rem;
  color: #fff;
  font-size: 0.16rem;
  text-align: center;
  line-height: 0.44rem;
  border-radius: 0.12rem;
}
.van-cell-arrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.popupBtn {
  width: 3.4rem;
  height: 0.44rem;
  background: #f2f3f5;
  border-radius: 0.08rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.44rem;
  color: #808080;
  margin: 0.16rem;
}
.birthdayBox {
  width: 100%;
  position: fixed;
  bottom: -100vh;
  left: 0;
  transition: all 0.2s linear;
}
.birthdayBox.active {
  bottom: 0;
}
.delPerson {
  /* width: 100%; */
  text-align: center;
  height: 17px;
  font-size: 12px;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 21px;
  color: #b1b1b3;
  /* text-decoration: underline; */
  margin-top: 0.24rem;
  position: absolute;
  bottom: 0.4rem;
  left: 50%;
  transform: translateX(-50%);
}
.arrow{
  margin-left: .08rem;
}
.van-cell__title{
  width: 1rem !important;
}
</style>